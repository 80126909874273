<template>
  <div>
    <v-data-table
        :items="patientsDataByDate"
        :height="pageHeight(160)"
        :headers="computedHeaders"
        :loading="loading"
        :no-data-text="$t('no-data-available')"
        :loading-text="$t('loading')"
        :footer-props="footerProps"
        :items-per-page="-1"
        class="pointer"
        :search="appBarSearch"
        fixed-header
        item-key="name"
        ref="table"
    >
      <template v-slot:item="{ item, index }">
        <tr :class="{ 'odd-row': index % 2 === 1, 'even-row': index % 2 === 0 }">
          <td class="font-size13 font-weight-medium text-left" style="height:6px !important;">{{ item.name  }}</td>
          <td class="font-size13 font-weight-medium text-left px-0"  style="height:6px !important;">{{ formatBirthDate(item.birthDate) }}</td>
<!--          <td class="font-size13 font-weight-medium px-0"  style="height:6px !important;">{{ item.mesaurmentsCountPerMonth }}</td>-->
          <td class="font-size13 font-weight-medium px-0"  style="height:6px !important;">
<!--            {{ getPatientProgress(item.billingData)}}%-->

            <div>
              <v-tooltip color="gray" top>
                <template v-slot:activator="{ on }">
                  <div class="mx-2 py-0">
                    <v-progress-linear
                        rounded
                        v-on="on"
                        class="white--text"
                        striped
                        :value="getPatientProgress(item.billingData)"
                        :color="progressColorForPatient(getPatientProgress(item.billingData))"
                        height="15">
                      {{ getPatientProgress(item.billingData) }} %
                    </v-progress-linear>
                  </div>
                </template>
                <div>
                  <v-progress-linear
                      style="width: 200px"
                      class="white--text my-2"
                      striped
                      rounded
                      :value="getPatientProgressForCPTCode(item.billingData?.percent99454)"
                      :color="progressColorForPatient(getPatientProgressForCPTCode(item.billingData?.percent99454))"
                      height="15">
                    99454: {{ getPatientProgressForCPTCode(item.billingData?.percent99454) }} %
                  </v-progress-linear>
                  <v-progress-linear
                      style="width: 200px"
                      class="white--text my-2"
                      striped
                      rounded
                      :value="getPatientProgressForCPTCode(item.billingData?.percent99457)"
                      :color="progressColorForPatient(getPatientProgressForCPTCode(item.billingData?.percent99457))"
                      height="15">
                    99457: {{ getPatientProgressForCPTCode(item.billingData?.percent99457) }} %
                  </v-progress-linear>
                  <v-progress-linear
                      style="width: 200px"
                      class="white--text my-2"
                      striped
                      rounded
                      :value="getPatientProgressForCPTCode(item.billingData?.percent994581st)"
                      :color="progressColorForPatient(getPatientProgressForCPTCode(item.billingData?.percent994581st))"
                      height="15">
                    99458 1st: {{ getPatientProgressForCPTCode(item.billingData?.percent994581st) }} %
                  </v-progress-linear>
                  <v-progress-linear
                      style="width: 200px"
                      class="white--text my-2"
                      striped
                      rounded
                      :value="getPatientProgressForCPTCode(item.billingData?.percent994582nd)"
                      :color="progressColorForPatient(getPatientProgressForCPTCode(item.billingData?.percent994582nd))"
                      height="15">
                    99458 2nd: {{ getPatientProgressForCPTCode(item.billingData?.percent994582nd) }} %
                  </v-progress-linear>
                </div>
              </v-tooltip>
            </div>

          </td>
          <td class="px-0" style="height:6px !important;">
            <v-tooltip color="chips" bottom>
              <template v-slot:activator="{ on, attrs }">
                <i
                    class="mdi mdi-calendar-multiselect mdi-18px color-primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="openPresenceDialog(item.patientId, item.name, item.presence)"
                ></i>
              </template>
              <span>{{ $t('set-patient-presence') }}</span>
            </v-tooltip>
          </td>
          <!--          <td class="font-size13 font-weight-medium pr-8"  style="height:6px !important;">{{ item.medicalNoteCountPerMonth }}</td>-->
          <td v-for="(measurement, index) in Object.keys(item.measurements)" v-bind:key="index"
              :style="{backgroundColor: getPatientStatusOnDate(item.patientId, getSelectedDate(formatIndex(index))) ? '' : '#bcbcbc'}"
              @click.stop="getPatientStatusOnDate(item.patientId, getSelectedDate(formatIndex(index))) && showDetails(item.patientId, item.name, item.measurements, item.medicalNotes, item.medicalNoteMedicationChange, {}, {}, {}, item.measurementsFrom3rdParty, item.deviceDefaultsValues, 'DAILY', getSelectedDate(formatIndex(index)))"
              class="px-0"
              style="height:6px !important;"
          >

            <v-tooltip bottom color="chips">
              <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                  <i :class="setClass(item, index)" :style="setStyle(item, index)">
                    <span v-if="hasDrugs(item, index)" class="blinking-dot"></span>
                  </i>
              </span>
              </template>

              <span class="text-left">{{ $t('overview-for') }} {{ item.name }} {{ $t('on-day') }} {{ formatBirthDate(selectedMonthAndYear + '-' + formatIndex(index)) }}</span><br>
              <span v-if="item.measurements && item.measurements[formatIndex(index)] && item.measurements[formatIndex(index)].length !== 0" class="d-flex">
                {{ $t('measurements') }}: {{ item.measurements[formatIndex(index)].length }}<br>
              </span>
              <span v-if="item.measurementsFrom3rdParty && item.measurementsFrom3rdParty[formatIndex(index)] && item.measurementsFrom3rdParty[formatIndex(index)].length !== 0" class="d-flex">
                {{ $t('ecp-measurements') }}: {{ item.measurementsFrom3rdParty[formatIndex(index)].length }}<br>
              </span>
              <span v-if="item.medicalNotes && item.medicalNotes[formatIndex(index)] && item.medicalNotes[formatIndex(index)].length !== 0" class="d-flex">
                {{ $t('medical-notes') }}: {{ item.medicalNotes[formatIndex(index)].length }}<br>
              </span>
              <span v-if="item.medicalNoteMedicationChange && item.medicalNoteMedicationChange[formatIndex(index)] && item.medicalNoteMedicationChange[formatIndex(index)].length !== 0" class="d-flex">
                {{ $t('has-drugs') }}: {{ item.medicalNoteMedicationChange[formatIndex(index)].length }}<br>
              </span>
              <span v-if="!getPatientStatusOnDate(item.patientId, getSelectedDate(formatIndex(index)))" class="d-flex">
                 {{ $t('out-of-facility') }}<br>
              </span>
            </v-tooltip>
          </td>
          <td  @click="showDetails(item.patientId, item.name,  item.measurements, item.medicalNotes, item.medicalNoteMedicationChange, item.w1MedicalNote, {}, {}, item.measurementsFrom3rdParty, item.deviceDefaultsValues, 'W1', getSelectedDate(formatIndex(index)))"
              class="px-0" style="height:6px !important; background-color: #e9e9e9">
            <i v-if="item.w1MedicalNote.length > 0" class="mdi mdi-checkbox-blank mdi-18px lightgrey"></i>
            <i v-else class="mdi mdi-checkbox-blank-outline mdi-18px" style="color:#ccc;"></i>
          </td>
          <td @click="showDetails(item.patientId, item.name,  item.measurements, item.medicalNotes, item.medicalNoteMedicationChange, item.w2MedicalNote, {}, {}, item.measurementsFrom3rdParty, item.deviceDefaultsValues, 'W2', getSelectedDate(formatIndex(index)))"
              class="px-0" style="height:6px !important; background-color:  #e9e9e9">
            <i v-if="item.w2MedicalNote.length > 0" class="mdi mdi-checkbox-blank mdi-18px lightgrey"></i>
            <i v-else class="mdi mdi-checkbox-blank-outline mdi-18px" style="color:#ccc;"></i>
          </td>
          <td @click="showDetails(item.patientId, item.name,  item.measurements, item.medicalNotes, item.medicalNoteMedicationChange, item.w3MedicalNote, {}, {}, item.measurementsFrom3rdParty, item.deviceDefaultsValues, 'W3', getSelectedDate(formatIndex(index)))"
              class="px-0" style="height:6px !important; background-color:  #e9e9e9">
            <i v-if="item.w3MedicalNote.length > 0" class="mdi mdi-checkbox-blank mdi-18px lightgrey"></i>
            <i v-else class="mdi mdi-checkbox-blank-outline mdi-18px" style="color:#ccc;"></i>
          </td>
          <td @click="showDetails(item.patientId, item.name,  item.measurements, item.medicalNotes, item.medicalNoteMedicationChange, item.w4MedicalNote, {}, {}, item.measurementsFrom3rdParty, item.deviceDefaultsValues, 'W4', getSelectedDate(formatIndex(index)))"
              class="px-0" style="height:6px !important; background-color:  #e9e9e9">
            <i v-if="item.w4MedicalNote.length > 0" class="mdi mdi-checkbox-blank mdi-18px lightgrey"></i>
            <i v-else class="mdi mdi-checkbox-blank-outline mdi-18px" style="color:#ccc;"></i>
          </td>
          <td  @click="showDetails(item.patientId, item.name,  item.measurements, item.medicalNotes, item.medicalNoteMedicationChange, {}, item.monthlyMedicalNote, item.medicalNoteNewToRPM, item.measurementsFrom3rdParty, item.deviceDefaultsValues, 'MONTHLY', getSelectedDate(formatIndex(index)))"
              class="px-0" style="height:6px !important; background-color:  #e9e9e9">
            <i v-if="item.monthlyMedicalNote.length > 0" class="mdi mdi-checkbox-blank mdi-18px lightgrey"></i>
            <i v-else class="mdi mdi-checkbox-blank-outline mdi-18px" style="color:#ccc;"></i>
          </td>
        </tr>
      </template>
      <template v-slot:footer.prepend>
        <span class="font-weight-bold mx-3">{{ $t('table-legend') }}:</span>
        <v-tooltip color="green" top>
          <template v-slot:activator="{ on }">
            <i class="mdi mdi-checkbox-blank mdi-18px color-green px-2" v-on="on"></i>
          </template>
          <span >{{ $t('there-is-a-measurement') }}</span>
        </v-tooltip>
        <v-tooltip color="orange" top>
          <template v-slot:activator="{ on }">
            <i class="mdi mdi-checkbox-blank mdi-18px color-orange px-2" v-on="on"></i>
          </template>
          <span>{{ $t('there-is-a-ecp-measurement') }}</span>
        </v-tooltip>
        <v-tooltip color="red" top>
          <template v-slot:activator="{ on }">
              <i class="mdi mdi-checkbox-blank mdi-18px color-red px-2" v-on="on"></i>
          </template>
          <span>{{ $t('abnormal-measurement') }}</span>
        </v-tooltip>
        <v-tooltip color="lightgray" top>
          <template v-slot:activator="{ on }">
            <i class="mdi mdi-checkbox-blank-outline mdi-18px color-gray px-2" style="color:#ccc;" v-on="on"></i>
          </template>
          <span>{{ $t('no-measurements-notes') }}</span>
        </v-tooltip>
        <v-tooltip color="lightgray" top>
          <template v-slot:activator="{ on }">
            <i class="ml-2" style="border:2px dashed black; font-size:17.5px; padding:0 1px; position: relative; height: 13px; width: 13px " v-on="on"></i>
          </template>
          <span>{{ $t('there-is-a-note') }}</span>
        </v-tooltip>
        <v-tooltip color="#bcbcbc" top>
          <template v-slot:activator="{ on }">
            <div class="mx-2 py-0" style="background-color: #bcbcbc">
            <i class="mdi mdi-checkbox-blank-outline mdi-18px color-gray" style="color:#ccc;" v-on="on"></i>
            </div>
          </template>
          <span>{{ $t('patient-presence') }}</span>
        </v-tooltip>
        <v-tooltip color="lightgray" top>
          <template v-slot:activator="{ on }">
            <i class="mdi mdi-circle-medium mdi-24px color-gray px-2" style="color:#ccc;" v-on="on"></i>
          </template>
          <span>{{ $t('has-drugs') }}</span>
        </v-tooltip>
<!--        <div class="mr-4 text-left">-->
<!--          <span style="display: block;" class="font-weight-bold">{{ $t('counts-days-with-measurements') }}</span>-->
<!--&lt;!&ndash;          <span style="display: block;" class="font-weight-bold">{{ $t('notes-days-with-medical-notes') }}</span>&ndash;&gt;-->
<!--        </div>-->
        <div>
          <v-tooltip color="gray" top>
            <template v-slot:activator="{ on }">
              <div class="mx-2 py-0">
                <v-progress-linear
                    v-on="on"
                    style="width: 300px"
                    class="white--text"
                    striped
                    rounded
                    :value="getOverAllProgressFor().toFixed(1)"
                    :color="progressColor"
                    height="20">
                  {{$t('progress')}}: {{ getOverAllProgressFor().toFixed(1) }} %
                </v-progress-linear>
              </div>
            </template>
            <div>
              <v-progress-linear
                  style="width: 300px"
                  class="white--text my-2"
                  striped
                  rounded
                  :value="getHospitalSummary()?.percent99454"
                  :color="progressColorForPatient(getHospitalSummary()?.percent99454)"
                  height="20">
                99454: {{ getHospitalSummary()?.percent99454 }} %
              </v-progress-linear>
              <v-progress-linear
                  style="width: 300px"
                  class="white--text my-2"
                  striped
                  rounded
                  :value="getHospitalSummary()?.percent99457"
                  :color="progressColorForPatient(getHospitalSummary()?.percent99457)"
                  height="20">
                99457: {{ getHospitalSummary()?.percent99457 }} %
              </v-progress-linear>
              <v-progress-linear
                  style="width: 300px"
                  class="white--text my-2"
                  striped
                  rounded
                  :value="getHospitalSummary()?.percent994581st"
                  :color="progressColorForPatient(getHospitalSummary()?.percent994581st)"
                  height="20">
                99458 1st: {{ getHospitalSummary()?.percent994581st }} %
              </v-progress-linear>
              <v-progress-linear
                  style="width: 300px"
                  class="white--text my-2"
                  striped
                  rounded
                  :value="getHospitalSummary()?.percent994582nd"
                  :color="progressColorForPatient(getHospitalSummary()?.percent994582nd)"
                  height="20">
                99458 2nd: {{ getHospitalSummary()?.percent994582nd }} %
              </v-progress-linear>
            </div>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <monthly-review-dialog
        :show.sync="reviewDialog.show"
        :patientName="reviewDialog.patientName"
        :measurements="reviewDialog.measurements"
        :medicalNotes="reviewDialog.medicalNotes"
        :medicalNoteNewToRPM="reviewDialog.medicalNoteNewToRPM"
        :medicalNoteMedicationChange="reviewDialog.medicalNoteMedicationChange"
        :measurementsFrom3rdParty="reviewDialog.measurementsFrom3rdParty"
        :deviceDefaultsValues="reviewDialog.deviceDefaultsValues"
        :dialogType="reviewDialog.dialogType"
        :selectedDate="reviewDialog.selectedDate"
        :startDate="reviewDialog.startDate"
        :endDate="reviewDialog.endDate"
        :patientId="reviewDialog.patientId"
        @reload-medical-notes="reloadData"
    />
    <presence-dialog
        :show.sync="presenceDialog.show"
        :patientId="presenceDialog.patientId"
        :patientName="presenceDialog.patientName"
        :presence="presenceDialog.presence"
        @reload-presence="reloadPresenceHandler"
        />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import filterbar from '@/data/store/modules/filterbar';
import PresenceDialog from '@/views/dialogs/PresenceDialog.vue';
import MonthlyReviewDialog from '@/views/dialogs/MonthlyReviewDialog';
import { formatterFilter } from '@/utils/luxon-formater';
import { aggregateData } from '@/utils/aggregateData';

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    MonthlyReviewDialog,
    PresenceDialog,
  },
  data () {
    return {
      timeLocalization: this.$i18n.locale,
      tooltip: {
        show: false,
        item: null,
        day: null,
      },
      reviewDialog: {
        show: false,
        patientId: 1,
        dialogType: '',
        selectedDate: '',
        startDate: '',
        endDate: '',
        patientName: '',
        measurements: [],
        medicalNotes: [],
        medicalNoteNewToRPM: [],
        medicalNoteMedicationChange: [],
        measurementsFrom3rdParty: [],
        deviceDefaultsValues: [],
      },
      presenceDialog: {
        show: false,
        patientId: 1,
        patientName: '',
        presence: [],
      },
      footerProps: { 'items-per-page-options': [-1, 5, 10, 50, 100] },
      loading: true,
      startDayOfMonth: 1,
      endDayOfMonth: this.getEndDayOfMonth(this.getCurrentMonthAndYear()),
      patientsDataByDate: [],
      patientsMedicalNoteByDate: [],
      patientBillingRecords: [],
      billingSummary: {
        cpt99453: 0,
        cpt99454: 0,
        cpt99457: 0,
        cpt994581st: 0,
        cpt994582nd: 0,
      },
      hospitalSummary: {
        cpt99453: 0,
        cpt99454: 0,
        cpt99457: 0,
        cpt994581st: 0,
        cpt994582nd: 0,
      },
      hospitalData: [],
    };
  },
  computed: {
    filterbar () {
      return filterbar;
    },
    ...mapGetters({
      pageHeight: 'pageHeight',
      hospitalId: 'authentication/getHospitalId',
      appBarSearch: 'filterbar/getSearch',
    }),
    ...mapState({
      billingsFromRepo: state => state.billings.billingSummaryForMonth,
      selectedMonthAndYear: (state) => state.filterbar.billingDate,
      patientMeasurementsData: (state) => state.medicaldevice.patientMeasurements,
      devicesDefaults: (state) => state.medicaldevice.devicesDefaults,
      relatedPatients: (state) => state.hospitals.relatedPatients,
      medicalNotesForHospital: (state) => state.appointments.medicalNotesForHospital,
      patientsPresenceForHospital: (state) => state.appointments.patientsPresenceForHospital,
      userData: (state) => state.authentication.userData,
    }),
    computedHeaders () {
      const headers = [
        { text: this.$t('patient-name'), value: 'name', class: 'table-header', align: 'start pl-4', width: 180 },
        { text: this.$t('birthdate'), value: 'birthdate', class: 'table-header px-0', align: 'start', width: 80 },
        // { text: this.$t('measurementsCounts'), value: 'mesaurmentsCountPerMonth', class: 'table-header px-0 pl-5', align: 'center', width: 5 },
        { text: this.$t('progress'), value: 'billingData', class: 'table-header px-0 pl-5', align: 'center', width: 5 },
        { text: this.$t('presence'), value: 'presence', class: 'table-header px-0', align: 'start', width: 40, sortable: false },
        // { text: this.$t('notes'), value: 'medicalNoteCountPerMonth', class: 'secondary px-0', align: 'center', width: 50 },
      ];
      for (let i = 0; i < this.endDayOfMonth; i++) {
        headers.push({
          text: this.formatIndex(i),
          value: `day${this.formatIndex(i)}`,
          class: 'table-header px-0',
          sortable: false,
          align: 'center',
        });
      }

      headers.push(
          { text: 'W1', value: 'W1', class: 'table-header px-0', align: 'center', sortable: false },
          { text: 'W2', value: 'W2', class: 'table-header px-0', align: 'center', sortable: false },
          { text: 'W3', value: 'W3', class: 'table-header px-0', align: 'center', sortable: false },
          { text: 'W4', value: 'W4', class: 'table-header px-0', align: 'center', sortable: false },
          // { text: 'W5', value: 'W5', class: 'secondary px-0', align: 'center', sortable: false },
          { text: 'M', value: 'M', class: 'table-header px-0', align: 'center', sortable: false },
      );

      return headers;
    },
    progressColor () {
      const selectedHospitalId = localStorage.getItem('selectedHospitalAssignedId');
      const progress = this.getOverAllProgressFor(selectedHospitalId).toFixed(1);
      if (progress < 75) {
        return 'red'; // Color for progress less than 50
      } else if (progress >= 75 && progress < 90) {
        return 'orange'; // Color for progress between 50 and 75
      } else {
        return 'green'; // Color for progress 75 and above
      }
    },
  },
  watch: {
    async selectedMonthAndYear (val) {
      this.loading = true;
      // this.selectedMonthAndYear = this.getEndDayOfMonth(val);
      this.endDayOfMonth = this.getEndDayOfMonth(val);
      await this.getmeasurementsforpatientsforhospital(val);
      await this.getMedicalNotesForHospital(val);
      await this.getBillingReport();
      const [year, month] = val.split('-');
      await this.organizedMedicalNoteData(year, month);
      await this.organizedData(year, month);
      await this.generateFullTimelines(this.patientsPresenceForHospital);
      this.loading = false;
    },
  },
  async mounted () {
    await this.getBillingReport();
    await this.getmeasurementsforpatientsforhospital(this.getCurrentMonthAndYear());
    await this.getDeviceDefaultValues();
    await this.patientPresenceForHospital();
    await this.getMedicalNotesForHospital(this.getCurrentMonthAndYear());
    await this.getAllMedicalStaff();
    const monthAndYear = this.getCurrentMonthAndYear();
    const [year, month] = monthAndYear.split('-');
    await this.organizedMedicalNoteData(year, month);
    await this.organizedData(year, month);
    await this.generateFullTimelines(this.patientsPresenceForHospital);
  },
  methods: {
    async getBillingReport () {
      let headers = {};
      if (this.selectedMonthAndYear === null || this.selectedMonthAndYear === undefined) {
        var selectedDate = new Date().toISOString().substr(0, 7);

        headers = {
          hospitalId: this.userData?.hospitalAssignedData?.id,
          month: parseInt(selectedDate.substring(5, 7)),
          year: parseInt(selectedDate.substring(0, 4)),
        };
      } else {
        headers = {
          hospitalId: this.userData?.hospitalAssignedData?.id,
          month: parseInt(this.selectedMonthAndYear.substring(5, 7)),
          year: parseInt(this.selectedMonthAndYear.substring(0, 4)),
        };
      }

      await this.$store.dispatch('billings/getbillingsummaryforallpatientsformonth', headers).then(() => {
        var patientNumber = this.billingsFromRepo.length;

          this.billingSummary.cpt99453 = ((this.billingSummary.cpt99453 / patientNumber) * 100).toFixed(0);
          this.billingSummary.cpt99454 = ((this.billingSummary.cpt99454 / patientNumber) * 100).toFixed(0);
          this.billingSummary.cpt99457 = ((this.billingSummary.cpt99457 / patientNumber) * 100).toFixed(0);
          this.billingSummary.cpt994581st = ((this.billingSummary.cpt994581st / patientNumber) * 100).toFixed(0);
          this.billingSummary.cpt994582nd = ((this.billingSummary.cpt994582nd / patientNumber) * 100).toFixed(0);

          this.organizeBillingData();
          // this.aggregateData();
      });
    },
    progressColorForPatient (progress) {
      if (progress < 75) {
        return 'red'; // Color for progress less than 50
      } else if (progress >= 75 && progress < 90) {
        return 'orange'; // Color for progress between 50 and 75
      } else {
        return 'green'; // Color for progress 75 and above
      }
    },
    // aggregateData () {
    //   const aggregatedData = {};
    //   // Iterate through the data
    //   for (const entry of this.billingsFromRepo) {
    //     if (entry.id === 0) { break; } // in this case exit from loop
    //     const hospitalId = entry.hospitalData.id;
    //
    //     if (!(hospitalId in aggregatedData)) {
    //       aggregatedData[hospitalId] = { counter: 0, counter99453: 0, percent99453: 0.0, counter99454: 0, percent99454: 0.0, counter99457: 0, percent99457: 0.0, counter994581st: 0, percent994581st: 0.0, counter994582nd: 0, percent994582nd: 0.0 };
    //     }
    //
    //     aggregatedData[hospitalId].counter += 1;
    //
    //     if (entry.cptCode99453?.status === 1) {
    //       aggregatedData[hospitalId].counter99453 += 1;
    //     }
    //     if (entry.cptCode99454?.status === 1) {
    //       aggregatedData[hospitalId].counter99454 += 1;
    //     }
    //     if (entry.cptCode99457?.status === 1) {
    //       aggregatedData[hospitalId].counter99457 += 1;
    //     }
    //     if (entry.cptCode99458First?.status === 1) {
    //       aggregatedData[hospitalId].counter994581st += 1;
    //     }
    //     if (entry.cptCode99458Second?.status === 1) {
    //       aggregatedData[hospitalId].counter994582nd += 1;
    //     }
    //   }
    //
    //   // Calculate percentages after the loop
    //   for (const hospitalId in aggregatedData) {
    //     const totalCounter = aggregatedData[hospitalId].counter;
    //     if (totalCounter > 0) {
    //       aggregatedData[hospitalId].percent99453 = ((aggregatedData[hospitalId].counter99453 / totalCounter) * 100).toFixed(1);
    //       aggregatedData[hospitalId].percent99454 = ((aggregatedData[hospitalId].counter99454 / totalCounter) * 100).toFixed(1);
    //       aggregatedData[hospitalId].percent99457 = ((aggregatedData[hospitalId].counter99457 / totalCounter) * 100).toFixed(1);
    //       aggregatedData[hospitalId].percent994581st = ((aggregatedData[hospitalId].counter994581st / totalCounter) * 100).toFixed(1);
    //       aggregatedData[hospitalId].percent994582nd = ((aggregatedData[hospitalId].counter994582nd / totalCounter) * 100).toFixed(1);
    //     }
    //   }
    //   this.hospitalData = aggregatedData;
    // },
    getOverAllProgressFor () {
      const selectedHospitalId = localStorage.getItem('selectedHospitalAssignedId');
      var result = this.getHospitalSummary(selectedHospitalId)?.percent99454 * 0.25 +
          this.getHospitalSummary(selectedHospitalId)?.percent99457 * 0.25 +
          this.getHospitalSummary(selectedHospitalId)?.percent994581st * 0.25 +
          this.getHospitalSummary(selectedHospitalId)?.percent994582nd * 0.25;
      if (isNaN(result)) result = 0;

      return result;
    },
    getHospitalSummary (selectedHospitalId) {
      let hospitalId = null;
      if (selectedHospitalId === null || selectedHospitalId === undefined) {
        hospitalId = localStorage.getItem('selectedHospitalAssignedId');
      } else {
        hospitalId = selectedHospitalId;
      }

      if (!hospitalId) {
        return null;
      }

      if (hospitalId in this.hospitalData) {
        return this.hospitalData[hospitalId];
      } else {
        return null;
      }
    },
    getPatientProgress (billingData) {
      if (billingData === null || billingData === undefined) return 0;
      const progress = (
          (parseInt(billingData.percent99454, 10) || 0) * 0.25 +
          (parseInt(billingData.percent99457, 10) || 0) * 0.25 +
          (parseInt(billingData.percent994581st, 10) || 0) * 0.25 +
          (parseInt(billingData.percent994582nd, 10) || 0) * 0.25
      );
      return progress.toFixed(1);
    },
    getPatientProgressForCPTCode (value) {
      if (value === null || value === undefined) return 0;
      const progress = parseInt(value, 10);
      return progress.toFixed(1);
    },
    async reloadPresenceHandler () {
      // window.location.reload();
      await this.patientPresenceForHospital();
      // const monthAndYear = this.getCurrentMonthAndYear();

      // const monthAndYear = this.selectedMonthAndYear;
      // const [year, month] = monthAndYear.split('-');
      // await this.organizedMedicalNoteData(year, month);
      // await this.organizedData(year, month);
      await this.generateFullTimelines(this.patientsPresenceForHospital);
    },
    async getAllMedicalStaff () {
      await this.$store.dispatch('hospitals/getRelatedMedicalStaff', this.userData.hospitalAssignedData.id);
      this.loading = false;
    },
    async reloadData () {
      await this.getBillingReport();
      // await this.getmeasurementsforpatientsforhospital(this.getCurrentMonthAndYear());
      await this.getMedicalNotesForHospital(this.selectedMonthAndYear);
      const monthAndYear = this.selectedMonthAndYear;
      const [year, month] = monthAndYear.split('-');
      await this.organizedMedicalNoteData(year, month);
      await this.organizedData(year, month);
    },
    hasAbnormalMeasurements (measurements) {
      if (!measurements) {
        return false;
      }
      return measurements.some(measurement => measurement.isAbnormal);
    },
    getSelectedDate (val) {
      if (this.selectedMonthAndYear === null || this.selectedMonthAndYear === undefined) {
        const date = this.getCurrentMonthAndYear();
        return date + '-' + val;
      }
      return this.selectedMonthAndYear + '-' + val;
    },
    formatBirthDate (val) {
      // Parse the input date string
      const dateObject = new Date(val);
      // Format the date as "Month Day Year"
      // return dateObject.toLocaleDateString('en-US', {
      //   month: 'short',
      //   day: 'numeric',
      //   year: 'numeric',
      // });

      return formatterFilter(
              dateObject,
              'date_med',
              this.timeLocalization,
              null,
          );
    },
    formatIndex (index) {
      return (index + 1 < 10 ? '0' : '') + (index + 1);
    },
    hasMeasurements (item, index) {
      return item.measurements && item.measurements[this.formatIndex(index)] && item.measurements[this.formatIndex(index)].length !== 0;
    },
    hasNoMeasurements (item, index) {
      return item.measurements && item.measurements[this.formatIndex(index)] && item.measurements[this.formatIndex(index)].length === 0;
    },
    hasThirdPartyMeasurements (item, index) {
      return item.measurementsFrom3rdParty && item.measurementsFrom3rdParty[this.formatIndex(index)] && item.measurementsFrom3rdParty[this.formatIndex(index)].length !== 0;
    },
    hasNoThirdPartyMeasurements (item, index) {
      return item.measurementsFrom3rdParty && item.measurementsFrom3rdParty[this.formatIndex(index)] && item.measurementsFrom3rdParty[this.formatIndex(index)].length === 0;
    },
    hasMedicalNotes (item, index) {
      return item.medicalNotes && item.medicalNotes[this.formatIndex(index)] && item.medicalNotes[this.formatIndex(index)].length !== 0;
    },
    hasNoMedicalNotes (item, index) {
      return item.medicalNotes && item.medicalNotes[this.formatIndex(index)] && item.medicalNotes[this.formatIndex(index)].length === 0;
    },
    hasDrugs (item, index) {
      try {
        return item.medicalNoteMedicationChange && item.medicalNoteMedicationChange[this.formatIndex(index)] && item.medicalNoteMedicationChange[this.formatIndex(index)].length > 0;
      } catch {
        return false;
      }
    },
    hasNoDrugs (item, index) {
      return item.medicalNoteMedicationChange && item.medicalNoteMedicationChange[this.formatIndex(index)] && item.medicalNoteMedicationChange[this.formatIndex(index)].length === 0;
    },
    hasNoMeasurementsOrNotes (item, index) {
      return item.measurementsFrom3rdParty[this.formatIndex(index)].length === 0 && item.measurements[this.formatIndex(index)].length === 0 && item.medicalNotes[this.formatIndex(index)].length !== 0;
    },
    hasMedicationChange (item, index) {
      return item.medicalNoteMedicationChange && item.medicalNoteMedicationChange[this.formatIndex(index)] && item.medicalNoteMedicationChange[this.formatIndex(index)].length > 0;
    },
    getMeasurementIconClass (item, index) {
      return this.hasAbnormalMeasurements(item.measurements[this.formatIndex(index)]) ? 'mdi mdi-checkbox-blank mdi-18px color-red' : 'mdi mdi-checkbox-blank mdi-18px color-green';
    },
    setClass (item, index) {
      if (this.hasMeasurements(item, index)) {
        if (this.hasThirdPartyMeasurements(item, index)) {
          return 'mdi mdi-checkbox-blank mdi-18px color-orange';
        }
        if (this.hasAbnormalMeasurements(item.measurements[this.formatIndex(index)])) {
          return 'mdi mdi-checkbox-blank mdi-18px color-red';
        } else {
          return 'mdi mdi-checkbox-blank mdi-18px color-green';
        }
      } else {
        if (this.hasThirdPartyMeasurements(item, index)) {
          return 'mdi mdi-checkbox-blank mdi-18px color-orange';
        }
        return 'mdi mdi-checkbox-blank-outline mdi-18px lightgrey';
      }
    },
    setStyle (item, index) {
      if (this.hasMedicalNotes(item, index)) {
        return {
          border: '2px dashed black',
          fontSize: '17.5px',
          padding: '0 1px',
          position: 'relative',
        };
      } else {
        return {
          border: 'none',
          fontSize: '17.5px',
          padding: '0 1px',
          position: 'relative',
        };
      }
    },
    // getMeasurementIconStyle (item, index) {
    //   return {
    //     border: (item.medicalNotes && item.medicalNotes[this.formatIndex(index)] && item.medicalNotes[this.formatIndex(index)].length !== 0) ? '2px dashed black' : 'none',
    //     fontSize: '17.5px',
    //     padding: '0 1px',
    //     position: 'relative',
    //   };
    // },
    // getNoMeasurementsStyle (item, index) {
    //   return {
    //     border: '2px dashed black',
    //     fontSize: '17.5px',
    //     padding: '0 1px',
    //     position: 'relative',
    //   };
    // },
    organizedData (year, month) {
      // Check if year and month are valid
      if (isNaN(year) || isNaN(month) || month < 1 || month > 12) {
        throw new Error('Invalid year or month.');
      }
      this.patientsDataByDate = [];
      const startDate = new Date(year, month - 1, 1); // Month is zero-based
      const endDate = new Date(year, month, 0);
      if (this.patientMeasurementsData) {
      this.patientMeasurementsData.forEach(patient => {
        const patientId = patient.patientId;
        const patientName = `${patient.firstName} ${patient.lastName}`;
        const birthDate = patient.birthdate;
        if (!this.patientsDataByDate[patientId]) {
          this.patientsDataByDate[patientId] = {
            patientId: patientId,
            name: patientName,
            birthDate: birthDate,
            timeLine: [],
            mesaurmentsCountPerMonth: 0,
            medicalNoteCountPerMonth: 0,
            measurements: {},
            deviceDefaultsValues: {},
            medicalNotes: {},
            w1MedicalNote: {},
            w2MedicalNote: {},
            w3MedicalNote: {},
            w4MedicalNote: {},
            w5MedicalNote: {},
            monthlyMedicalNote: {},
            measurementsFrom3rdParty: {},
            medicalNoteMedicationChange: {},
            medicalNoteNewToRPM: {},
            billingData: {},
            // abnormalMeasurementExsist: false,
          };
        }

        // Initialize measurements for all dates in the specified month
        const currentDate = new Date(startDate);
        // eslint-disable-next-line no-unmodified-loop-condition
        while (currentDate <= endDate) {
          const day = String(currentDate.getDate()).padStart(2, '0');
          this.patientsDataByDate[patientId].measurements[day] = [];
          currentDate.setDate(currentDate.getDate() + 1);
        }

        // Fill in measurements data

        if (patient.medicalDeviceMeasurementsV2) {
          // var tempMeasurementCount = 0;
          patient.medicalDeviceMeasurementsV2.forEach(measurement => {
            var deviceesDefaults = this.getUserDeviceDefaultsForUser(patientId);
            const dateParts = measurement.measuredAtDate.split('-');
            const tempDay = dateParts[2];
            if (dateParts[0] === year && dateParts[1] === month) {
              measurement.isAbnormal = this.checkIfMeasurementIsAbnormal(measurement, deviceesDefaults);
              // if (measurement.isAbnormal) this.patientsDataByDate[patientId].abnormalMeasurementExsist = true;
              this.patientsDataByDate[patientId].measurements[tempDay].push(measurement);
              // this.patientsDataByDate[patientId].deviceDefaultsValues = this.getUserDeviceDefaultsForUser(patientId);
              // this.patientsDataByDate[patientId].medicalNotes = this.patientsMedicalNoteByDate[patientId].medicalNotes;
              // this.patientsDataByDate[patientId].w1MedicalNote = this.patientsMedicalNoteByDate[patientId].w1MedicalNote;
              // this.patientsDataByDate[patientId].w2MedicalNote = this.patientsMedicalNoteByDate[patientId].w2MedicalNote;
              // this.patientsDataByDate[patientId].w3MedicalNote = this.patientsMedicalNoteByDate[patientId].w3MedicalNote;
              // this.patientsDataByDate[patientId].w4MedicalNote = this.patientsMedicalNoteByDate[patientId].w4MedicalNote;
              // this.patientsDataByDate[patientId].w5MedicalNote = this.patientsMedicalNoteByDate[patientId].w5MedicalNote;
              // this.patientsDataByDate[patientId].monthlyMedicalNote = this.patientsMedicalNoteByDate[patientId].monthlyMedicalNote;
              // this.patientsDataByDate[patientId].measurementsFrom3rdParty = this.patientsMedicalNoteByDate[patientId].measurementsFrom3rdParty;
            }
            // if (this.patientsDataByDate[patientId].measurements[tempDay].length > 0) tempMeasurementCount++;
          });
          this.patientsDataByDate[patientId].mesaurmentsCountPerMonth = this.countItemsDoneForMonthByDay(this.patientsDataByDate[patientId].measurements);
          this.patientsDataByDate[patientId].medicalNoteCountPerMonth = this.countItemsDoneForMonthTotal(this.patientsMedicalNoteByDate[patientId].medicalNotes);
          this.patientsDataByDate[patientId].deviceDefaultsValues = this.getUserDeviceDefaultsForUser(patientId);
          this.patientsDataByDate[patientId].medicalNotes = this.patientsMedicalNoteByDate[patientId].medicalNotes;
          this.patientsDataByDate[patientId].w1MedicalNote = this.patientsMedicalNoteByDate[patientId].w1MedicalNote;
          this.patientsDataByDate[patientId].w2MedicalNote = this.patientsMedicalNoteByDate[patientId].w2MedicalNote;
          this.patientsDataByDate[patientId].w3MedicalNote = this.patientsMedicalNoteByDate[patientId].w3MedicalNote;
          this.patientsDataByDate[patientId].w4MedicalNote = this.patientsMedicalNoteByDate[patientId].w4MedicalNote;
          this.patientsDataByDate[patientId].w5MedicalNote = this.patientsMedicalNoteByDate[patientId].w5MedicalNote;
          this.patientsDataByDate[patientId].monthlyMedicalNote = this.patientsMedicalNoteByDate[patientId].monthlyMedicalNote;
          this.patientsDataByDate[patientId].measurementsFrom3rdParty = this.patientsMedicalNoteByDate[patientId].measurementsFrom3rdParty;
          this.patientsDataByDate[patientId].medicalNoteMedicationChange = this.patientsMedicalNoteByDate[patientId].medicalNoteMedicationChange;
          this.patientsDataByDate[patientId].medicalNoteNewToRPM = this.patientsMedicalNoteByDate[patientId].medicalNoteNewToRPM;
          this.patientsDataByDate[patientId].billingData = this.patientBillingRecords[patientId];
        }
      });
      }
    },
    countItemsDoneForMonthByDay (array) {
      let count = 0;
      for (const key in array) {
        if (array[key].length > 0) {
          count++;
        }
      }
      return count;
    },
    countItemsDoneForMonthTotal (array) {
      let count = 0;
      for (const key in array) {
        count += array[key].length;
      }
      return count;
    },
    checkIfMeasurementIsAbnormal (measurement, devicesDefaults) {
      const data = JSON.parse(measurement.measuredDataJson);
      const tempDeviceDefaults = devicesDefaults.find(device => device.deviceType === measurement.deviceType);
      var deviceDefaults = JSON.parse(tempDeviceDefaults.deviceDefaultValuesJson);
      var isAbnormal = false;
      switch (parseInt(measurement.deviceType)) {
        case 1:
          isAbnormal = false;
          if (data?.Temperature_C > deviceDefaults.MaxTemperature || data?.Temperature_C < deviceDefaults.MinTemperature) {
            isAbnormal = true;
          } else {
            isAbnormal = false;
          }
          return isAbnormal;
        case 3:
          isAbnormal = false;

          var systolicChecking = data?.Sys > deviceDefaults.SystolicMax || data?.Sys < deviceDefaults.SystolicMin;
          var diastolicChecking = data?.Dias > deviceDefaults.DiastolicMax || data?.Dias < deviceDefaults.DiastolicMin;
          var heartBeatChecking = data?.Pul > deviceDefaults.HeartBeatMax || data?.Pul < deviceDefaults.HeartBeatMin;

          if (systolicChecking || diastolicChecking || heartBeatChecking) {
            isAbnormal = true;
          } else {
            isAbnormal = false;
          }
          return isAbnormal;
        case 2:
          isAbnormal = false;

          if (data?.Spo2 < deviceDefaults.SpO2) {
            isAbnormal = true;
          } else {
            isAbnormal = false;
          }
          return isAbnormal;
        case 5:
          return false;
        case 6:
          isAbnormal = false;

          var glucoseChecking = data?.glucose_mmolL > deviceDefaults.BloodSugarLevelMax || data?.glucose_mmolL < deviceDefaults.BloodSugarLevelMin;
          if (glucoseChecking) {
            isAbnormal = true;
          } else {
            isAbnormal = false;
          }
          return isAbnormal;
        default :
          return false;
      }
    },
    organizedMedicalNoteData (year, month) {
      // Check if year and month are valid
      if (isNaN(year) || isNaN(month) || month < 1 || month > 12) {
        throw new Error('Invalid year or month.');
      }
      // this.patientsDataByDate
      // this.patientsMedicalNoteByDate
      this.patientsMedicalNoteByDate = [];
      const startDate = new Date(year, month - 1, 1); // Month is zero-based
      const endDate = new Date(year, month, 0);

      this.medicalNotesForHospital.forEach(patient => {
        const patientId = patient.patientId;
        const patientName = `${patient.firstName} ${patient.lastName}`;
        if (!this.patientsMedicalNoteByDate[patientId]) {
          this.patientsMedicalNoteByDate[patientId] = {
            name: patientName,
            medicalNotes: {},
            w1MedicalNote: {},
            w2MedicalNote: {},
            w3MedicalNote: {},
            w4MedicalNote: {},
            w5MedicalNote: {},
            monthlyMedicalNote: {},
            measurementsFrom3rdParty: {},
            medicalNoteMedicationChange: {},
            medicalNoteNewToRPM: {},
          };
        }

        // Initialize measurements for all dates in the specified month
        const currentDate = new Date(startDate);
        // eslint-disable-next-line no-unmodified-loop-condition
        while (currentDate <= endDate) {
          const day = String(currentDate.getDate()).padStart(2, '0');
          this.patientsMedicalNoteByDate[patientId].medicalNotes[day] = [];
          this.patientsMedicalNoteByDate[patientId].w1MedicalNote = [];
          this.patientsMedicalNoteByDate[patientId].w2MedicalNote = [];
          this.patientsMedicalNoteByDate[patientId].w3MedicalNote = [];
          this.patientsMedicalNoteByDate[patientId].w4MedicalNote = [];
          this.patientsMedicalNoteByDate[patientId].w5MedicalNote = [];
          this.patientsMedicalNoteByDate[patientId].monthlyMedicalNote = [];
          this.patientsMedicalNoteByDate[patientId].measurementsFrom3rdParty[day] = [];
          this.patientsMedicalNoteByDate[patientId].medicalNoteMedicationChange[day] = [];
          this.patientsMedicalNoteByDate[patientId].medicalNoteNewToRPM = [];
          currentDate.setDate(currentDate.getDate() + 1);
        }

        // Fill in measurements data
        if (patient.medicalNotes) {
          patient.medicalNotes.forEach(medicalNote => {
            const startDateParts = medicalNote.medicalNoteData.medicalNoteStartDate.split('-');
            const endDateParts = medicalNote.medicalNoteData.medicalNoteEndDate.split('-');
            const tempStartDay = startDateParts[2];
            // const tempEndDay = endDateParts[2];
            // const tempEndDay = endDateParts[2];
            if ((startDateParts[0] === year && startDateParts[1] === month && startDateParts[2] === tempStartDay) &&
                (endDateParts[0] === year && endDateParts[1] === month && endDateParts[2] === tempStartDay) &&
                (medicalNote.medicalNoteData.medicalNoteCategoryType !== 'MN-3RDS' && (medicalNote.medicalNoteData.medicalNoteCategoryType !== 'MN-DRUGS'))) {
              this.patientsMedicalNoteByDate[patientId].medicalNotes[tempStartDay].push(medicalNote);
            }

            // TODO fix this beeter in future
            if (medicalNote.medicalNoteData.medicalNoteCategoryType === 'MN-WEEKLY-NORMAL' || medicalNote.medicalNoteData.medicalNoteCategoryType === 'MN-WEEKLY-ABNORMAL') {
              const tempWeek = this.getWeek(medicalNote.medicalNoteData.medicalNoteStartDate, medicalNote.medicalNoteData.medicalNoteEndDate);
              switch (tempWeek.weekOfTheMonth) {
                case 1:
                  this.patientsMedicalNoteByDate[patientId].w1MedicalNote.push(medicalNote);
                  break;
                case 2:
                  this.patientsMedicalNoteByDate[patientId].w2MedicalNote.push(medicalNote);
                  break;
                case 3:
                  this.patientsMedicalNoteByDate[patientId].w3MedicalNote.push(medicalNote);
                  break;
                case 4:
                  this.patientsMedicalNoteByDate[patientId].w4MedicalNote.push(medicalNote);
                  break;
              }
            }
            if (medicalNote.medicalNoteData.medicalNoteCategoryType === 'MN-MONTHLY') {
              this.patientsMedicalNoteByDate[patientId].monthlyMedicalNote.push(medicalNote);
            }
            if (medicalNote.medicalNoteData.medicalNoteCategoryType === 'MN-NEW-RPM') {
              this.patientsMedicalNoteByDate[patientId].medicalNoteNewToRPM.push(medicalNote);
            }
            if (medicalNote.medicalNoteData.medicalNoteCategoryType === 'MN-3RDS') {
              const startDateParts = medicalNote.medicalNoteData.medicalNoteStartDate.split('-');
              const endDateParts = medicalNote.medicalNoteData.medicalNoteEndDate.split('-');
              const tempStartDay = startDateParts[2];
              // const tempEndDay = endDateParts[2];
              // const tempEndDay = endDateParts[2];
              if ((startDateParts[0] === year && startDateParts[1] === month && startDateParts[2] === tempStartDay) && (endDateParts[0] === year && endDateParts[1] === month && endDateParts[2] === tempStartDay)) {
                this.patientsMedicalNoteByDate[patientId].measurementsFrom3rdParty[tempStartDay].push(medicalNote);
              }
            }
            if (medicalNote.medicalNoteData.medicalNoteCategoryType === 'MN-DRUGS') {
              const startDateParts = medicalNote.medicalNoteData.medicalNoteStartDate.split('-');
              const endDateParts = medicalNote.medicalNoteData.medicalNoteEndDate.split('-');
              const tempStartDay = startDateParts[2];
              // const tempEndDay = endDateParts[2];
              // const tempEndDay = endDateParts[2];
              if ((startDateParts[0] === year && startDateParts[1] === month && startDateParts[2] === tempStartDay) && (endDateParts[0] === year && endDateParts[1] === month && endDateParts[2] === tempStartDay)) {
                this.patientsMedicalNoteByDate[patientId].medicalNoteMedicationChange[tempStartDay].push(medicalNote);
              }
            }
          });
        }
      });
    },
    organizeBillingData () {
      this.billingsFromRepo.forEach(billingRecord => {
        const patientId = billingRecord.patientId;
        if (!this.patientBillingRecords[patientId]) {
          this.patientBillingRecords[patientId] = {};
        }

        if (billingRecord.cptCode99453?.status === 1) {
          this.billingSummary.cpt99453++;
        }
        if (billingRecord.cptCode99454?.status === 1) {
          this.billingSummary.cpt99454++;
        }
        if (billingRecord.cptCode99457?.status === 1) {
          this.billingSummary.cpt99457++;
        }
        if (billingRecord.cptCode99458First?.status === 1) {
          this.billingSummary.cpt994581st++;
        }
        if (billingRecord.cptCode99458Second?.status === 1) {
          this.billingSummary.cpt994582nd++;
        }

        let percent99454 = 0;
        let totalMeasurements99454 = 0;
        let measurement99454IsBillable = false;
        let measurement99457IsBillable = false;
        let measurement994581stIsBillable = false;
        let measurement994582ndIsBillable = false;
        if (billingRecord.cptCode99454 !== null) {
          if (billingRecord.cptCode99454.totalMeasurements < 16) {
            percent99454 = ((billingRecord.cptCode99454.totalMeasurements / 16) * 100).toFixed(0);
            totalMeasurements99454 = billingRecord.cptCode99454.totalMeasurements;
          } else {
            totalMeasurements99454 = billingRecord.cptCode99454.totalMeasurements;
            percent99454 = 100;
            measurement99454IsBillable = true;
          }
        }

        const totalSec = billingRecord.cptCode99457?.totalIntervalInSeconds + billingRecord.cptCode99458First?.totalIntervalInSeconds + billingRecord.cptCode99458Second?.totalIntervalInSeconds;

        let percent99457 = 0;
        let percent994581st = 0;
        let percent994582nd = 0;

        let seconds99457 = 0;
        let seconds994581st = 0;
        let seconds994582nd = 0;

        if (totalSec < 1200) {
          seconds99457 = totalSec;
          percent99457 = (((totalSec / 1200) * 100) < 100) ? ((totalSec / 1200) * 100).toFixed(1) : 100;

          seconds994581st = 0;
          percent994581st = 0;

          seconds994582nd = 0;
          percent994582nd = 0;
        }

        if (totalSec >= 1200 && totalSec < 2400) {
          seconds99457 = 1200;
          percent99457 = 100;
          measurement99457IsBillable = true;

          seconds994581st = totalSec - 1200;
          percent994581st = (((seconds994581st / 1200) * 100) < 100) ? ((seconds994581st / 1200) * 100).toFixed(0) : 100;

          seconds994582nd = 0;
          percent994582nd = 0;
        }

        if (totalSec >= 2400 && totalSec < 3600) {
          seconds99457 = 1200;
          percent99457 = 100;

          seconds994581st = 1200;
          percent994581st = 100;
          measurement994581stIsBillable = true;

          seconds994582nd = totalSec - 2400;
          percent994582nd = (((seconds994582nd / 1200) * 100) < 100) ? ((seconds994582nd / 1200) * 100).toFixed(0) : 100;
        }

        if (totalSec >= 3600) {
          seconds99457 = 1200;
          percent99457 = 100;

          seconds994581st = 1200;
          percent994581st = 100;

          seconds994582nd = totalSec - 2400;
          percent994582nd = 100;
          measurement994582ndIsBillable = true;
        }

        const myBillings = {
          totalMeasurements99454: totalMeasurements99454,
          percent99454: percent99454,
          percent99457: percent99457,
          percent994581st: percent994581st,
          percent994582nd: percent994582nd,
          seconds99457: seconds99457,
          seconds994581st: seconds994581st,
          seconds994582nd: seconds994582nd,
          m99454IsBillable: measurement99454IsBillable,
          m99457IsBillable: measurement99457IsBillable,
          m994581stIsBillable: measurement994581stIsBillable,
          m994582ndIsBillable: measurement994582ndIsBillable,
        };
        this.patientBillingRecords[patientId] = myBillings;
      });
      this.hospitalData = aggregateData(this.billingsFromRepo);
    },
    generateFullTimelines (usersPresenceData) {
      // Helper function to generate date range
      function dateRange (startDate, endDate) {
        const dates = [];
        const currentDate = new Date(startDate);
        // eslint-disable-next-line no-unmodified-loop-condition
        while (currentDate <= endDate) {
          dates.push(new Date(currentDate));
          currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
      }

      // Function to generate full timeline for a single user
      function generateFullTimeline (presenceData) {
        // Sort presence data by presenceDateChangeAt
        presenceData.sort((a, b) => new Date(a.presenceDateChangeAt) - new Date(b.presenceDateChangeAt));

        // Initialize the timeline
        const fullTimeline = [];
        let initialStatus = null;
        let currentStatus = null;

        // Iterate and fill dates
        for (let i = 0; i < presenceData.length; i++) {
          const currentEntry = presenceData[i];
          const currentDate = new Date(currentEntry.presenceDateChangeAt);
          const presenceStatus = currentEntry.presenceStatus;

          // Set initial status
          if (i === 0) {
            initialStatus = presenceStatus;
          }

          // Set current status
          if (i === presenceData.length - 1) {
            currentStatus = presenceStatus;
          }

          // Add the status for the current date
          fullTimeline.push({
            date: currentDate.toISOString().split('T')[0],
            presenceStatus: presenceStatus,
          });

          // If there is a next entry, fill the dates between the current and next entry
          if (i < presenceData.length - 1) {
            const nextEntry = presenceData[i + 1];
            const nextDate = new Date(nextEntry.presenceDateChangeAt);

            // Fill the dates between currentDate and nextDate
            const datesInRange = dateRange(new Date(currentDate.getTime() + 86400000), new Date(nextDate.getTime() - 86400000));
            datesInRange.forEach(date => {
              fullTimeline.push({
                date: date.toISOString().split('T')[0],
                presenceStatus: presenceStatus,
              });
            });
          }
        }

        // Return the full timeline with initial and current status
        return {
          timeline: fullTimeline,
          initialStatus: initialStatus,
          currentStatus: currentStatus,
        };
      }

      // Map over usersPresenceData and populate timelinesByUser
      usersPresenceData.forEach(userPresenceData => {
        this.patientsDataByDate[userPresenceData.patientId].timeLine = {
          patientId: userPresenceData.patientId,
          ...generateFullTimeline(userPresenceData.presenceData),
        };
      });
    },
    getPatientStatusOnDate (patientId, date) {
      // Find the timeline for the specified patientId
      const patientTimeline = this.patientsDataByDate[patientId]?.timeLine;

      // If patientTimeline is undefined (patientId not found in timelines), return true
      if (!patientTimeline) {
        return true;
      }

      // Parse the requested date
      const requestedDate = new Date(date);

      // If patientTimeline is empty array, return true
      if (!patientTimeline.timeline || patientTimeline.timeline.length === 0) {
        return true;
      }

      // Search for the presence status on the specified date
      const statusEntry = patientTimeline.timeline.find(entry => entry.date === date);

      if (statusEntry) {
        return statusEntry.presenceStatus;
      } else {
        // Check if the requested date is earlier than the date of the first object in the timeline
        const firstEntryDate = new Date(patientTimeline.timeline[0].date);
        if (requestedDate < firstEntryDate) {
          return true;
        }

        // If no entry found for the exact date, determine the status based on closest available date
        const closestEntry = patientTimeline.timeline.reduce((closest, current) => {
          const closestDate = new Date(closest.date);
          const currentDate = new Date(current.date);

          // Calculate differences in milliseconds
          const closestDiff = Math.abs(requestedDate - closestDate);
          const currentDiff = Math.abs(requestedDate - currentDate);

          // Return the entry closest to the requested date
          return currentDiff < closestDiff ? current : closest;
        }, { date: null, presenceStatus: true }); // Provide initial value to handle empty array

        return closestEntry.presenceStatus;
      }
    },
    // getPatientStatusOnDate (patientId, date) {
    //     // Find the timeline for the specified patientId
    //     const patientTimeline = this.timelinesByUser.find(timeline => timeline.patientId === patientId);
    //
    //     if (!patientTimeline) {
    //       console.error(`Patient with ID ${patientId} not found in timelines.`);
    //       // If there is no record fro that patient than you need to consider the patient to be inside the hospitalid/officeid/homecare facility
    //       return true;
    //     }
    //
    //     // Parse the requested date
    //     const requestedDate = new Date(date);
    //
    //     // Parse the latest date in the timeline
    //     const latestTimelineDate = new Date(patientTimeline.timeline[patientTimeline.timeline.length - 1].date);
    //
    //     // If requested date is later than the latest date in the timeline
    //     if (requestedDate > latestTimelineDate) {
    //       // Return the presence status of the last entry in the timeline
    //       return patientTimeline.timeline[patientTimeline.timeline.length - 1].presenceStatus;
    //     }
    //
    //     // Search for the presence status on the specified date
    //     const statusEntry = patientTimeline.timeline.find(entry => entry.date === date);
    //
    //     if (statusEntry) {
    //       return statusEntry.presenceStatus;
    //     } else {
    //       // If no entry found for the exact date, determine the status based on closest available date
    //       const closestEntry = patientTimeline.timeline.reduce((closest, current) => {
    //         const closestDate = new Date(closest.date);
    //         const currentDate = new Date(current.date);
    //
    //         // Calculate differences in milliseconds
    //         const closestDiff = Math.abs(requestedDate - closestDate);
    //         const currentDiff = Math.abs(requestedDate - currentDate);
    //
    //         // Return the entry closest to the requested date
    //         return currentDiff < closestDiff ? current : closest;
    //       });
    //
    //       return closestEntry.presenceStatus;
    //     }
    //   },
    getUserDeviceDefaultsForUser (userId) {
      var temp = this.devicesDefaults.find(userDefaults => {
        return userDefaults.assignedToUserId === userId; // Assuming assignedToUserId is the property representing the user
      });

      if (temp) {
        return temp.userDeviceDefaults;
      } else {
        return [];
      }
    },
    getCurrentMonthAndYear () {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
      return `${year}-${month}`;
    },
    getEndDayOfMonth (date) {
      // Split the date string into year and month parts
      const [year, month] = date.split('-');
      // Create a new Date object for the first day of the next month
      // Subtract 1 day to get the last day of the current month
      const lastDayOfMonth = new Date(year, month, 0).getDate();
      return lastDayOfMonth;
    },
    datesAreGreater (date1, date2) {
      // Convert the date strings to Date objects
      const tempDate1 = new Date(date1);
      const tempDate2 = new Date(date2);

      if (tempDate1 >= tempDate2) {
        return true;
      }
      if (tempDate1 < tempDate2) {
        return false;
      }
    },
    datesAreSmaller (date1, date2) {
      // Convert the date strings to Date objects
      const tempDate1 = new Date(date1);
      const tempDate2 = new Date(date2);

      if (tempDate1 <= tempDate2) {
        return true;
      }
      if (tempDate1 > tempDate2) {
        return false;
      }
    },
    getWeek (startDate, endDate) {
      const [year, month] = startDate.split('-').map(Number);

      const firstDayOfW1 = this.getFirstDayOfMonth(year, month);
      const lastDayOfW1 = this.addDays(firstDayOfW1, 6);

      if ((this.datesAreGreater(startDate, firstDayOfW1) && this.datesAreSmaller(startDate, lastDayOfW1)) ||
          (this.datesAreSmaller(endDate, lastDayOfW1) && this.datesAreGreater(endDate, firstDayOfW1))) {
        return {
          weekOfTheMonth: 1,
          weekOfTheMonthDescription: 'W1',
        };
      }

      const firstDayOfW2 = this.addDays(firstDayOfW1, 7);
      const lastDayOfW2 = this.addDays(this.addDays(firstDayOfW1, 6), 7);

      if ((this.datesAreGreater(startDate, firstDayOfW2) && this.datesAreSmaller(startDate, lastDayOfW2)) ||
          (this.datesAreSmaller(endDate, lastDayOfW2) && this.datesAreGreater(endDate, firstDayOfW2))) {
        return {
          weekOfTheMonth: 2,
          weekOfTheMonthDescription: 'W2',
        };
      }

      const firstDayOfW3 = this.addDays(firstDayOfW1, 14);
      const lastDayOfW3 = this.addDays(this.addDays(firstDayOfW1, 6), 14);

      if ((this.datesAreGreater(startDate, firstDayOfW3) && this.datesAreSmaller(startDate, lastDayOfW3)) ||
          (this.datesAreSmaller(endDate, lastDayOfW3) && this.datesAreGreater(endDate, firstDayOfW3))) {
        return {
          weekOfTheMonth: 3,
          weekOfTheMonthDescription: 'W3',
        };
      }

      const firstDayOfW4 = this.addDays(firstDayOfW1, 21);
      const lastDayOfW4 = this.lastDayOfSelectedMonthV2(year, month);

      if ((this.datesAreGreater(startDate, firstDayOfW4) && this.datesAreSmaller(startDate, lastDayOfW4)) ||
          (this.datesAreSmaller(endDate, lastDayOfW4) && this.datesAreGreater(endDate, firstDayOfW4))) {
        return {
          weekOfTheMonth: 4,
          weekOfTheMonthDescription: 'W4',
        };
      }
    },
    getWeekBoundary (weekIdentifier, yearMonth) {
      const [year, month] = yearMonth.split('-').map(Number);
      const mondayDate = this.getFirstMondayOfMonth(year, month);

      switch (weekIdentifier) {
        case 'W1':
          return {
            startWeekDate: mondayDate,
            endWeekDate: this.addDays(mondayDate, 6),
          };
          // break;
        case 'W2':
          return {
            startWeekDate: this.addDays(mondayDate, 7),
            endWeekDate: this.addDays(this.addDays(mondayDate, 6), 7),
          };
        case 'W3':
          return {
            startWeekDate: this.addDays(mondayDate, 14),
            endWeekDate: this.addDays(this.addDays(mondayDate, 6), 14),
          };
        case 'W4':
          return {
            startWeekDate: this.addDays(mondayDate, 21),
            endWeekDate: this.lastDayOfSelectedMonthV2(year, month),
          };
        // case 'W5':
        //   return {
        //     startWeekDate: this.addDays(mondayDate, 28),
        //     endWeekDate: this.addDays(this.addDays(mondayDate, 6), 28),
        //   };
      }
    },
    lastDayOfSelectedMonthV2 (year, month) {
      const lastDay = new Date(year, month, 1);

      // Return the last day of the selected month
      return lastDay.toISOString().slice(0, 10);
    },
    getFirstDayOfMonth (year, month) {
      var firstDay = new Date(year, month - 1, 1);
      return firstDay.toISOString().substr(0, 10);
    },
    getFirstMondayOfMonth (year, month) {
      var firstDay = new Date(year, month - 1, 1);

      // // Calculate the difference between the first day and Monday
      // var dayOfWeek = firstDay.getDay();
      // var daysUntilMonday = (dayOfWeek === 1) ? 1 : (9 - dayOfWeek);
      //
      // // Adjust the date to the first Monday
      // firstDay.setDate(firstDay.getDate() + daysUntilMonday);

      // Return the date in the format YYYY-MM-DD
      return firstDay.toISOString().substr(0, 10);
    },
    addDays (dateString, daysToAdd) {
      const date = new Date(dateString); // Convert the string to a Date object
      date.setDate(date.getDate() + daysToAdd); // Add the specified number of days
      return date.toISOString().substr(0, 10); // Return the new date in YYYY-MM-DD format
    },
    weekOfMonth (dateString) {
      const tempDate = new Date(dateString);
      const startOfMonth = new Date(tempDate.getFullYear(), tempDate.getMonth(), 1);
      const dayOfMonth = tempDate.getDate();
      const dayOfWeek = startOfMonth.getDay(); // Day of the week for the 1st day of the month

      // Calculate the number of days to the start of the week (Monday)
      const startOffset = (dayOfWeek === 0 ? 6 : dayOfWeek - 1); // Adjust to Monday start
      const adjustedDate = dayOfMonth + startOffset - 1;

      // Calculate week number within May
      const weekNumber = Math.floor(adjustedDate / 7) + 1;

      return weekNumber;
    },
    firstDayOfCurrentWeek (date) {
      const tempDate = new Date(date);
      const dayOfWeek = tempDate.getDay();
      const diff = tempDate.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1);
      tempDate.setDate(diff);
      return tempDate.toISOString().slice(0, 10);
    },
    lastDayOfCurrentWeek (date) {
      const tempDate = new Date(date);
      const dayOfWeek = tempDate.getDay();
      const diff = tempDate.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1) + 6;
      tempDate.setDate(diff);
      return tempDate.toISOString().slice(0, 10);
    },
    firstDayOfSelectedMonth (date) {
      const tempDate = new Date(date);
      tempDate.setDate(1); // Set the date to the 1st of the current month
      return tempDate.toISOString().slice(0, 10); // Return the date in YYYY-MM-DD format
    },
    lastDayOfSelectedMonth (date) {
      const today = new Date();
      const year = date.getFullYear();
      const month = date.getMonth();

      // Set the date to the last day of the selected month
      const lastDay = new Date(year, month + 1, 1);

      // Check if today's date is after the last day of the selected month
      if (today > lastDay) {
        // If it is, return the last day of the selected month
        return lastDay.toISOString().slice(0, 10);
      } else {
        // Otherwise, return today's date
        return today.toISOString().slice(0, 10);
      }
    },
    async getmeasurementsforpatientsforhospital (val) {
      const [year, month] = val.split('-'); // Split the input string into year and month
      const body = {
        hospitalId: localStorage.getItem('selectedHospitalAssignedId'),
        byMonth: month,
        byYear: year,
      };
      await this.$store.dispatch('medicaldevice/getmeasurementsforpatientsforhospital', body);
    },
    async getDeviceDefaultValues () {
      const hospitalId = localStorage.getItem('selectedHospitalAssignedId');
      await this.$store.dispatch('medicaldevice/getdevicedefaultsforhospital', hospitalId);
    },
    async patientPresenceForHospital () {
      const hospitalId = localStorage.getItem('selectedHospitalAssignedId');
      await this.$store.dispatch('appointments/getPatientPresenceForHospital', hospitalId);
    },
    async getMedicalNotesForHospital (val) {
      const [year, month] = val.split('-'); // Split the input string into year and month
      const body = {
        hospitalId: localStorage.getItem('selectedHospitalAssignedId'),
        byMonth: month,
        byYear: year,
      };
      await this.$store.dispatch('appointments/getmedicalnotesforhospital', body);
    },
    getDataInRange (data, startDate, endDate) {
      // Extract day from the date and parse to integer
      const startIndex = parseInt(startDate.split('-')[2], 10); // Remove leading zeros
      let endIndex = parseInt(endDate.split('-')[2], 10); // Remove leading zeros

      if (endIndex < startIndex) {
        // startIndex = endIndex;
        endIndex = 31;
      }

      const measurementsInRange = [];

      for (let i = startIndex; i <= endIndex; i++) {
        // Ensure index has two digits
        const index = i < 10 ? '0' + i : '' + i;
        if (data[index]) {
          measurementsInRange.push(...data[index]); // Push elements of data[index] individually
        }
      }

      return measurementsInRange;
    },
    getFirstAndLastDayOfMonth (dateString) {
      // Extract year and month from the input string
      const [year, month] = dateString.split('-').map(Number);

      // Set the date to the first day of the month
      const firstDay = new Date(year, month - 1, 1);

      // Set the date to the last day of the month
      const lastDay = new Date(year, month, 1);

      // Return an object containing the first and last day of the month
      return {
        firstDay: firstDay.toISOString().slice(0, 10),
        lastDay: lastDay.toISOString().slice(0, 10),
      };
    },
    openPresenceDialog (patientId, patientName, presence) {
      this.presenceDialog.show = true;
      this.presenceDialog.patientId = patientId;
      this.presenceDialog.patientName = patientName;
      this.presenceDialog.presence = presence;
    },
    showDetails (patientId, patientName, measurements, medicalNotes, medicalNoteMedicationChange, weeklyNote, monthlyNote, medicalNoteNewToRPM, measurementsFrom3rdParty, deviceDefaultsValues, dialogType, selectedDate) {
      let tempStartDate = '';
      let tempEndDate = '';

      let tempMeasurements = measurements;
      let tempMedicalNotes = medicalNotes;
      let tempMeasurementsFrom3rdParty = measurementsFrom3rdParty;
      let tempMedicalNoteMedicationChange = medicalNoteMedicationChange;
      // let tempMedicalNoteNewToRPM = medicalNoteNewToRPM;

      const parts = selectedDate.split('-');
      const year = parts[0];
      const month = parts[1];
      const yearMonth = year + '-' + month;

      let range = {};

      switch (dialogType) {
        case 'DAILY':
          tempStartDate = selectedDate;
          tempEndDate = selectedDate;
          tempMedicalNotes = this.getDataInRange(medicalNotes, tempStartDate, tempEndDate);
          break;
        case 'W1':
          range = this.getWeekBoundary('W1', yearMonth);
          tempStartDate = yearMonth + '-01';
          tempEndDate = range.endWeekDate;
          tempMedicalNotes = weeklyNote;
          break;
        case 'W2':
          range = this.getWeekBoundary('W2', yearMonth);
          tempStartDate = range.startWeekDate;
          tempEndDate = range.endWeekDate;
          tempMedicalNotes = weeklyNote;
          break;
        case 'W3':
          range = this.getWeekBoundary('W3', yearMonth);
          tempStartDate = range.startWeekDate;
          tempEndDate = range.endWeekDate;
          tempMedicalNotes = weeklyNote;
          break;
        case 'W4':
          range = this.getWeekBoundary('W4', yearMonth);
          tempStartDate = range.startWeekDate;
          tempEndDate = range.endWeekDate;
          tempMedicalNotes = weeklyNote;
          break;
        // case 'W5':
        //   range = this.getWeekBoundary('W5', yearMonth);
        //   tempStartDate = range.startWeekDate;
        //   tempEndDate = range.endWeekDate;
        //   break;
        case 'MONTHLY':
          range = this.getFirstAndLastDayOfMonth(yearMonth);
          tempStartDate = yearMonth + '-01';
          tempEndDate = range.lastDay;
          tempMedicalNotes = monthlyNote;
          // tempMedicalNoteNewToRPM = medicalNoteNewToRPM;
          break;
        default:
          break;
      }
      tempMeasurements = this.getDataInRange(measurements, tempStartDate, tempEndDate);

      tempMeasurementsFrom3rdParty = this.getDataInRange(measurementsFrom3rdParty, tempStartDate, tempEndDate);
      tempMedicalNoteMedicationChange = this.getDataInRange(medicalNoteMedicationChange, tempStartDate, tempEndDate);

      if (measurements === null || measurements === undefined || Object.keys(measurements).length === 0) {
        measurements = [];
      }
      if (medicalNotes === null || medicalNotes === undefined || Object.keys(medicalNotes).length === 0) {
        medicalNotes = [];
      }
      if (measurementsFrom3rdParty === null || measurementsFrom3rdParty === undefined || Object.keys(measurementsFrom3rdParty).length === 0) {
        measurementsFrom3rdParty = [];
      }

      if (medicalNoteMedicationChange === null || medicalNoteMedicationChange === undefined || Object.keys(medicalNoteMedicationChange).length === 0) {
        medicalNoteMedicationChange = [];
      }
      if (medicalNoteNewToRPM === null || medicalNoteNewToRPM === undefined || Object.keys(medicalNoteNewToRPM).length === 0) {
        medicalNoteNewToRPM = [];
      }
      if (deviceDefaultsValues === null || deviceDefaultsValues === undefined || Object.keys(deviceDefaultsValues).length === 0) {
        deviceDefaultsValues = [];
      }
      this.reviewDialog.show = true;
      this.reviewDialog.patientId = patientId;
      this.reviewDialog.dialogType = dialogType;
      this.reviewDialog.selectedDate = selectedDate;
      this.reviewDialog.startDate = tempStartDate;
      this.reviewDialog.endDate = tempEndDate;
      this.reviewDialog.patientName = patientName;
      this.reviewDialog.measurements = tempMeasurements;
      this.reviewDialog.medicalNotes = tempMedicalNotes;
      this.reviewDialog.medicalNoteNewToRPM = medicalNoteNewToRPM;
      this.reviewDialog.measurementsFrom3rdParty = tempMeasurementsFrom3rdParty;
      this.reviewDialog.medicalNoteMedicationChange = tempMedicalNoteMedicationChange;
      this.reviewDialog.deviceDefaultsValues = deviceDefaultsValues;

      const data = {
        id: patientId,
        startDate: tempStartDate,
        endDate: tempEndDate,
      };
      this.$store.dispatch('nosessionhubs/getHubsDataCustomDate', data);
    },
  },
};
</script>

<style>
.color-primary {
  color: #1976d2; /* Customize the primary color */
}

.color-red {
  color: red;
}

th{
  height:20px !important;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.blinking-dot {
  position: absolute;
  top: 49%;
  left: 52%;
  transform: translate(-50%, -50%);
  width: 5px;
  height: 5px;
  background-color: black;
  border-radius: 50%;
  animation: blink 0.5s infinite;
}
</style>
